import moment from 'moment';
import { useEffect } from 'react';
import { useState } from 'react';
import { isMobile } from "../../../constants/user";
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa";
import Button from '../Button';
import { FaFileExport } from "react-icons/fa6";
const ReportTimeLog = ({ data, headinglist, exportToExcel }) => {
    const [filterData, setFilterData] = useState(data);
    const _isMobile = isMobile();
    const [mobileData, setMobileData] = useState(data);
    useEffect(() => {
        setFilterData(data);
    }, [data])
    const toggleview = (projectIndex) => {
        const updatedData = [...mobileData];
        updatedData[projectIndex].toggle = !updatedData[projectIndex].toggle;
        setMobileData(updatedData);
    };
    return (
        <div class="relative overflow-x-auto pl-3  md:p-3">
            <div className='text-right py-2'>
                <div className='flex text-darkgray text-sm justify-end'>
                    <span className='flex items-center bg-gray px-2 rounded-sm ' onClick={exportToExcel}> <FaFileExport className='text-yellow cursor-pointer' />
                        <Button label={'Export'} className={'  font-medium py-1 px-4 rounded  pt-1 md:w-full'} /></span>
                </div>

            </div>
            {_isMobile ? <div>
                {filterData?.map((item, projectIndex) => (
                    <>
                        <div className="flex items-center justify-between  border border-b border-white">
                            <span className="text-[13px] bg-secbg w-24 px-1 py-2">
                                userName
                            </span>
                            <div className="bg-lightgray w-full rounded-sm px-1 py-[8px] flex justify-between items-center">
                                <span className="text-sm sm:w-full sm:flex sm:justify-between">
                                    {item.userName}
                                    <span onClick={() => toggleview(projectIndex)}>{item?.toggle ? (
                                        <FaMinus className="text-sm" />
                                    ) : (
                                        <FaPlus className="text-sm" />
                                    )}</span>
                                </span>
                            </div>
                        </div>
                        {item?.toggle && (
                            <>
                                <div className="flex items-center justify-between  border border-b border-white">
                                    <span className="text-[13px] bg-secbg w-24 px-1 py-2">
                                        Minutes
                                    </span>
                                    <div className="bg-lightgray w-full rounded-sm px-1 py-[8px] flex justify-between items-center">
                                        <span className="text-sm">
                                            {item.time}
                                        </span>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between  border border-b border-white">
                                    <span className="text-[13px] bg-secbg w-24 px-1 py-2">
                                        Project
                                    </span>
                                    <div className="bg-lightgray w-full rounded-sm px-1 py-[8px] flex justify-between items-center">
                                        <span className="text-sm">
                                            {item.project}
                                        </span>
                                    </div>
                                </div>

                                <div className="flex items-center justify-between  border border-b border-white">
                                    <span className="text-[13px] bg-secbg w-24 px-1 py-2">
                                        WorkItemId
                                    </span>
                                    <div className="bg-lightgray w-full rounded-sm px-1 py-[8px] flex justify-between items-center">
                                        <span className="text-sm">
                                            {item.workItemId}
                                        </span>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between  border border-b border-white">
                                    <span className="text-[13px] bg-secbg w-24 px-1 py-2">
                                        Date
                                    </span>
                                    <div className="bg-lightgray w-full rounded-sm px-1 py-[8px] flex justify-between items-center">
                                        <span className="text-sm">
                                            {moment(item.date).format('DD/MM/YYYY')}
                                        </span>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between  border border-b border-white">
                                    <span className="text-[13px] bg-secbg w-24 px-1 py-2">
                                        Comment
                                    </span>
                                    <div className="bg-lightgray w-full rounded-sm px-1 py-[8px] flex justify-between items-center">
                                        <span className="text-sm">
                                            {item.comment || '--'}
                                        </span>
                                    </div>
                                </div>
                            </>
                        )}
                    </>

                ))}

            </div> :
                <div>

                    <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 md:overflow-auto md:block">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 border-gray bg-secbg border-t ">
                            <tr>
                                {headinglist.map((headinglist, index) => (
                                    <th scope="col" class="px-3 py-4 border border-gray  border-t-0 border-b-0 capitalize  ">
                                        {headinglist}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {filterData?.map((item, index) => (
                                <tr className='bg-white  dark:bg-gray-800 dark:border-gray-700 hover:bg-gray  ' key={index}>
                                    <td className='px-4 py-4 border border-gray capitalize'>{item.time}</td>
                                    <td className='px-4 py-4 border border-gray  capitalize'>{item.project}</td>
                                    <td className='px-4 py-4 border border-gray  capitalize'><div className='flex gap-1 items-center'>{item.userName}</div></td>
                                    <td className='px-4 py-4 border border-gray  capitalize'><div className='flex gap-1 items-center'>{item.workItemId}</div></td>
                                    <td className='px-4 py-4 border border-gray  capitalize'>{moment(item.date).format('DD/MM/YYYY')}</td>
                                    <td className='px-4 py-4 border border-gray  capitalize'>{item.comment}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>}

        </div>

    );
};

export default ReportTimeLog;



