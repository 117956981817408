import React from 'react'
import ReportItem from '../../Atoms/ReportItem'
import Sidebar from '../../Molecules/Sidebar';
import TopBar from '../../Molecules/TopBar';


const WorkItems = () => {
  const breadcrumbData = [
    {
      name: 'Reports',
      href: '/reports',
    }
  ];

  return <>
    <div className='flex  md:mt-0 md:flex-col'>
      {/* <Sidebar /> */}
      <div className='w-full'>
        <TopBar breadcrumbData={breadcrumbData} />
        <ReportItem />
      </div>
    </div>
  </>
}

export default WorkItems;