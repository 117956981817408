import { useTable } from 'react-table';
import { BiTask, BiSolidUserCircle, BiDotsHorizontal } from 'react-icons/bi';
import { GoDotFill } from 'react-icons/go'
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useEffect } from 'react';
import { useState } from 'react';
import { FaRegClock } from "react-icons/fa";
import { isMobile } from "../../../constants/user";
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa";

const TimelogList = ({ data, headinglist, searchKeyword }) => {
  const _isMobile = isMobile();
  const [filterData, setFilterData] = useState(data);
  const [mobileData, setMobileData] = useState(data);
  const filterBySearch = (searchKeyword) => {
    const updatedList = data.filter(
      (item) => item.title.toLowerCase().indexOf(searchKeyword?.toLowerCase()) !== -1 ||
      item.workitemId.toString().indexOf(searchKeyword?.toLowerCase()) !== -1 
    );
    setFilterData(updatedList);
  };

  const toggleview = (projectIndex) => {
    const updatedData = [...mobileData];
    updatedData[projectIndex].toggle = !updatedData[projectIndex].toggle;
    setMobileData(updatedData);
  };

  useEffect(() => {
    setFilterData(data);
    filterBySearch(searchKeyword)
  }, [searchKeyword, data])


  return (
    <div class="relative overflow-x-auto pl-3 lg:pl-0 md:p-3">
      {_isMobile ?
        <div>
          {mobileData?.map((item, projectIndex) => (
            <>
              <div className="flex items-center justify-between  border border-b border-white">
                <span className="text-[12px] bg-secbg w-24 px-1 py-2">
                  ID
                </span>

                <div className="bg-lightgray w-full rounded-sm px-1 py-[8px] flex justify-between items-center">
                  <span className="text-[12px] flex items-center justify-between w-full">
                    {item.workitemId}
                    <span onClick={() => toggleview(projectIndex)}>{item?.toggle ? (
                      <FaMinus className="text-sm" />
                    ) : (
                      <FaPlus className="text-sm" />
                    )}
                    </span>
                  </span>
                </div>
              </div>

              {item?.toggle && (
                <>
                  <div className="flex items-center justify-between  border border-b border-white">
                    <span className="text-[12px] bg-secbg w-24 px-1 py-2">
                      Title
                    </span>

                    <div className="bg-lightgray w-full rounded-sm px-1 py-[8px] flex justify-between items-center">
                      <span className="text-[12px]">
                        {/* <Link to="/timelog" state={{ data: { 'workTypeId': item.workitemId, 'workItem': item.title, 'projectId': item.projectId } }} className='hover:underline md:text-sm sm:w-40 sm:truncate sm:block' >{item.title}</Link> */}
                        <Link to={`/timelog/${item.projectId}/${item.workitemId}}`} className='hover:underline md:text-sm sm:w-40 sm:truncate sm:block' >{item.title}</Link>
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center justify-between  border border-b border-white">
                    <span className="text-[12px] bg-secbg w-24 px-1 py-2">
                      Assigned To
                    </span>

                    <div className="bg-lightgray w-full rounded-sm px-1 py-[8px] flex justify-between items-center">
                      <span className="text-[12px]">
                        {item.assinedTo}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center justify-between  border border-b border-white">
                    <span className="text-[12px] bg-secbg w-24 px-1 py-2">
                      State
                    </span>

                    <div className="bg-lightgray w-full rounded-sm px-1 py-[8px] flex justify-between items-center">
                      <span className="text-[12px]">
                        <div className='flex gap-1 items-center'><GoDotFill className='text-blue text-[12px]' />{item.state}</div>
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center justify-between  border border-b border-white">
                    <span className="text-[12px] bg-secbg w-24 px-1 py-2">
                      Area Path
                    </span>

                    <div className="bg-lightgray w-full rounded-sm px-1 py-[8px] flex justify-between items-center">
                      <span className="text-[12px]">
                        <div className='flex gap-1 items-center'>{item.projectName}</div>
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center justify-between  border border-b border-white">
                    <span className="text-[12px] bg-secbg w-24 px-1 py-2">
                      Tags
                    </span>

                    <div className="bg-lightgray w-full rounded-sm px-1 py-[8px] flex justify-between items-center">
                      <span className="text-[12px]">
                        <div className='flex gap-1 items-center'>{item.tags || '--'}</div>
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center justify-between  border border-b border-white">
                    <span className="text-[12px] bg-secbg w-24 px-1 py-2">
                      Comment
                    </span>

                    <div className="bg-lightgray w-full rounded-sm px-1 py-[8px] flex justify-between items-center">
                      <span className="text-[12px]">
                        <div className='flex gap-1 items-center'>{item.comment || '--'}</div>
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center justify-between  border border-b border-white">
                    <span className="text-[12px] bg-secbg w-24 px-1 py-2">
                      Activity Date
                    </span>

                    <div className="bg-lightgray w-full rounded-sm px-1 py-[8px] flex justify-between items-center">
                      <span className="text-[12px]">
                        <div className='flex gap-1 items-center'>{moment(item.activityDate).format("MM/DD/YYYY")}</div>
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center justify-between  border border-b border-white">
                    <span className="text-[12px] bg-secbg w-24 px-1 py-2">
                      Time
                    </span>

                    <div className="bg-lightgray w-full rounded-sm px-1 py-[8px] flex justify-between items-center">
                      <span className="text-[12px]">
                        <div className='flex items-center gap-1 '>
                          {moment(item.activityDate).format("h:mm:ss A")}
                        </div>
                      </span>
                    </div>
                  </div>
                </>

              )}

            </>


          ))}

        </div> : <div>
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 border-gray bg-secbg border-t ">
              <tr>
                <th scope="col" className="px-3 py-3 md:py-1 md:px-1 w-5 border border-gray border-t-0 border-b-0  ">
                  <div class="round-box">
                    <div class="round">
                      <input type="checkbox" id="checkbox" />
                      <label for="checkbox"></label>
                    </div>
                  </div>
                </th>
                {headinglist.map((headinglist, index) => (
                  <th scope="col" class="px-3 py-4 md:py-1 md:px-1 border border-gray  border-t-0 border-b-0 capitalize ">
                    {headinglist}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filterData?.map((item, index) => (
                <tr className='bg-white  dark:bg-gray-800 dark:border-gray-700 hover:bg-gray  ' key={index}>
                  <td className='px-3 md:py-1 md:px-1 py-4 border border-gray'>
                    <div class="container">
                      <div class="round">
                        <input type="checkbox" id="checkbox" />
                        <label for="checkbox"></label>
                      </div>
                    </div>
                  </td>
                  <td className='px-4 py-4 md:py-1 md:px-1 border border-gray'>{item.workitemId}</td>
                  <td className='px-4 py-4 md:py-1 md:px-1 cursor-auto border md:text-sm border-gray' ><div className='flex  justify-between '>
                    <div className='flex gap-1 items-center'><BiTask className='text-yellow' />
                      {/* <Link to="/timelog" state={{ data: { 'workTypeId': item.workitemId, 'workItem': item.title, 'projectId': item.projectId } }} className='hover:underline md:text-sm sm:w-56 sm:truncate ' >{item.title}</Link> */}
                      <Link to={`/timelog/${item.projectId}/${item.workitemId}`} className='hover:underline md:text-sm sm:w-56 sm:truncate ' >{item.title}</Link>
                    </div>
                    {/* <div className='hover:bg-lightgray'><BiDotsHorizontal /></div> */}
                  </div>
                  </td>
                  <td className='px-4 md:py-2 md:px-2 md:text-sm py-4 border border-gray '><div className='flex items-center justify-between'><div className='flex gap-1 items-center'><BiSolidUserCircle className='text-darkgray text-xl text-green' />{item.assinedTo}</div> </div></td>
                  <td className='px-4 md:py-2 md:px-2 md:text-sm py-4 border border-gray'><div className='flex gap-1 items-center'><GoDotFill className='text-blue text-[12px]' />{item.state}</div></td>
                  <td className='px-4 md:py-2 md:px-2 md:text-sm py-4 border border-gray'>{item.projectName}</td>
                  <td className='px-4 md:py-2 md:px-2 md:text-sm py-4 border border-gray'>{item.tags}</td>
                  <td className='px-4 md:py-2 md:px-2 md:text-sm py-4 border border-gray'>{item.comment}</td>
                  <td className='px-4 md:py-2 md:px-2 md:text-sm py-4 border border-gray'>{moment(item.activityDate).format("MM/DD/YYYY")}</td>
                  <td className='px-4 md:py-2 md:px-2 md:text-sm py-4 border border-gray time'>
                    <div className='flex items-center gap-1 '>
                      <FaRegClock className='text-lg text-textgray' />{moment(item.activityDate).format("h:mm:ss A")}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>}

    </div>

  );
};

export default TimelogList;



