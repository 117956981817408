import React, { useState, useEffect } from "react";
import { FiFilter } from 'react-icons/fi'
import { BsChevronDown } from 'react-icons/bs'
import { RxCross1 } from 'react-icons/rx'
import Button from '../Button'
import axios from "axios";
import Select from 'react-select';
import { Link, useParams } from 'react-router-dom';


const FilterBar = ({ filterData, setFilterData, searchKeyword, setSearchKeyword, searchButton, setSearchButton, isContentVisible }) => {
  const receivedData = useParams();
  const { project_id } = receivedData;
  const [projectEnum, setProjectEnum] = useState([]);
  const [assignedData, setAssignedData] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  const [selectedAssignedTo, setSelectedAssignedTo] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [clearInput, setClearInput] = useState('');

  const initialOptions = [
    { label: 'Task', value: 'Task', checked: false },
    { label: 'User Story', value: 'User Story', checked: false }
  ];

  const CheckboxOption = ({ innerProps, label, isSelected }) => (
    <div {...innerProps} style={{ cursor: 'pointer' }}>
      <input type="checkbox" checked={isSelected} readOnly className="m-1" />
      {label}
    </div>
  );


  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}TimeLog/GetProjectAndWorkItemEnum`
      );
      setProjectEnum(response.data?.projectEnum);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAssignedData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}TimeLog/GetAllUsersByProject?ProjectId=${project_id}`
      );
      setAssignedData(response?.data?.value);
    } catch (error) {
      console.log(error);
    }
  };

  const clearFilter = () => {
    setSearchKeyword('');
    setSelectedType([]);
    setSelectedAssignedTo([]);
    setSelectedStatus([]);
    setFilterData([]);
    setSearchButton(!searchButton);
  };


  useEffect(() => {
    fetchData();
    fetchAssignedData();
  }, [filterData]);

  const handleTypeChange = (selectedOptions) => {
    setSelectedType(selectedOptions);
    setFilterData({ ...filterData, "type": selectedOptions.map((item) => item.label) })
  };

  const handleAssignChange = (selectedOptions) => {
    setSelectedAssignedTo(selectedOptions);
    setFilterData({ ...filterData, "assignedTo": selectedOptions.map((item) => item.label) })
  }

  const handleStatusChange = (selectedOptions) => {
    setSelectedStatus(selectedOptions);
    setFilterData({ ...filterData, "state": selectedOptions.map((item) => item.label) })
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setSearchButton(!searchButton)
    }
  };


  return (
    <div>
      {
        isContentVisible &&
        <div className='flex gap-2 items-center filter-sections bg-background px-6 py-4 ml-3 md:ml-0 md:mb-2  md:flex-wrap'>
          <div className='filter-bar'>
            <div className='flex flex-col'>
              <label className='text-[12px] pb-1 '>Keyword</label>
              <input type="search" placeholder='Enter keyword' value={searchKeyword} className='border  text-[12px] w-full  h-[38px] rounded-[4px] border-gray text-black  focus:outline-none pl-2'
                onChange={(e) => setSearchKeyword(e.target.value)}
                onKeyDown={handleKeyDown} 
              />
            </div>
          </div>

          <div className='filter-bar'>
            <div className='flex flex-col'>
              <label className='text-[12px] pb-1'>Types</label>
              <Select
                options={initialOptions}
                isMulti
                value={selectedType}
                onChange={handleTypeChange}
                placeholder="Select options"
                className="basic-multi-select  text-[12px] "
                classNamePrefix="select "
                closeMenuOnSelect={false}
                hideSelectedOptions={true}
                components={{ Option: CheckboxOption }}
                onKeyDown={handleKeyDown} // Handle Enter key press here
              />
            </div>
          </div>

          <div className='filter-bar'>
            <div className='flex flex-col'>
              <label className='text-[12px] pb-1'>Assigned to</label>
              <Select
                options={assignedData?.map(option => ({ value: option.id, label: option.displayName }))}
                isMulti
                value={selectedAssignedTo}
                onChange={handleAssignChange}
                placeholder="Select options"
                className="basic-multi-select   text-[12px]"
                classNamePrefix="select"
                closeMenuOnSelect={false}
                hideSelectedOptions={true}
                components={{ Option: CheckboxOption }}
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>

          <div className='filter-bar'>
            <div className='flex flex-col'>
              <label className='text-[12px] pb-1'>Status</label>
              <Select
                options={projectEnum?.map(option => ({ value: option.id, label: option.enum }))}
                isMulti
                value={selectedStatus}
                onChange={handleStatusChange}
                placeholder="Select options"
                className="basic-multi-select  text-[12px]"
                classNamePrefix="select"
                closeMenuOnSelect={false}
                hideSelectedOptions={true}
                components={{ Option: CheckboxOption }}
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>
          <Button label={'Search'} className={'bg-yellow hover:bg-blue-700 text-white text-sm h-[38px]  font-medium py-1 px-4 rounded mt-[20px] pt-1'} onClick={() => setSearchButton(!searchButton)} />
          <Button label={'Clear'} className={'bg-yellow hover:bg-blue-700 text-white text-sm h-[38px]  font-medium py-1 px-4 rounded mt-[20px] pt-1'} onClick={clearFilter} />
        </div>
      }

    </div>
  )
}

export default FilterBar