import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';

const Pagination = ({ totalItems, setPage }) => {
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
    setPage(selected + 1);
  };


  return (
    <div className='pagination-flex '>
      <ReactPaginate
        pageCount={Math.ceil(totalItems / itemsPerPage)}
        pageRangeDisplayed={5}
        marginPagesDisplayed={2}
        onPageChange={handlePageChange}
        containerClassName="pagination"
        activeClassName="active"
      />
    </div>
  );
};

export default Pagination;