import { React, useState, useEffect } from 'react'
import { IoMdCopy, IoIosSave, IoIosRefresh } from 'react-icons/io'
import { LiaCommentsSolid, LiaEyeSolid } from 'react-icons/lia'
import { getLocalStorageData } from '../../../constants/user';
import { jwtDecode } from "jwt-decode";
function TopBar({ data }) {
  const [loginData, setLoginData] = useState(null);
  const userId = getLocalStorageData('access_token');

  useEffect(() => {
    const token = userId !== null ? jwtDecode(userId) : '';
    setLoginData(token);
  }, [userId]);
  function getInitials(fullName) {
    const words = fullName.split(' ');
    const initials = words.map((word) => word.charAt(0)).join('');
    return initials.toUpperCase();
  }

  return (
    <div className='p-4 bg-lightgreen'>
      <div className='flex gap-1'>
        <a className='text-xs bg-lightgreen' href="#">{data?.type} Name</a>
      </div>
      <div className='mt-2'>
        <div className='flex gap-1 '>
          <label htmlFor="text" className='text-xl md:text-sm font-bold text-green'>{data?.workItemId}
          </label>
          <div className='pl-1 flex w-full   hover:border-gray copysection'>
            <input
              type="text"
              id="inputField"
              value={data?.title}
              className='font-bold w-full focus:outline-none focus:ring-blue-100 focus:ring-1 pl-2 text-xl md:text-sm bg-lightgreen sm:truncate sm:w-60'
            />
            <div className='bg-gray p-1 saveusbtn'>
              <IoMdCopy />
            </div>
          </div>
        </div>
        <div>
        </div>
        <div className='mt-2'>
          <div className='flex gap-2 justify-between'>
            <div className='flex gap-2 items-center w-full'>
              <div>
                <div className=' bg-green rounded-full py-1 px-2'>
                  {/* <AiOutlineUser className='text-md' /> */}
                  <div><span className='text-white'>{data && data?.assignedto && getInitials(data?.assignedto)}</span></div>
                </div>
              </div>
              <select className='focus:outline-none focus:ring-blue-100 focus:ring-1 selectbox-custome  text-sm w-full max-w-[200px] md:max-w-[100px] appearance-none focus:appearance-show bg-lightgreen'
                id="selectBox"
              >

                <option value="">{data?.assignedto}</option>
                <option value="">unsigned</option>
                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
              </select>
              <div className='flex items-center gap-2'>
                <LiaCommentsSolid className='text-xl' />
                <label className='text-[14px]  md:text-[10px]'>{data?.comment} Comments</label>
                <div className='text-[12px] md:text-[10px] bg-lightgray rounded-sm p-1 px-2 md:px-1'>Add tag</div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default TopBar