import React, { useState, useEffect } from "react";
import Button from '../Button'
import axios from "axios";
import { getLocalStorageData, clearLocalStorage } from '../../../constants/user';


const ReportFilter = ({ reportFilter, setReportFilter, exportToExcel, searchButton, setSearchButton }) => {
    const [project, setProject] = useState([]);
    const [error, setError] = useState({});
    const [isContentVisible, setIsContentVisible] = useState(false);
    const [projectUser, setProjectUser] = useState([]);
    const [adUsers, setAdusers] = useState([]);

    const userId = getLocalStorageData('access_token');


    const fetchADUsers = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}TimeLog/GetAllADUsers`, { token: userId });
            setAdusers(response.data.value);
        } catch (error) {
            console.log(error);
        }
    };

    const fetchProjectData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}TimeLog/GetAllProjects`);
            setProject(response.data.value);
        } catch (error) {
            console.log(error);
        }
    };

    const fetchProjectUsers = async () => {
        try {
            const { ProjectId } = reportFilter;
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}TimeLog/GetAllUsersByProject?ProjectId=${ProjectId}`);
            setProjectUser(response?.data?.value);
        } catch (error) {
            console.log(error);
        }
    };


    const toggleContentVisibility = () => {
        setIsContentVisible(!isContentVisible);
    }

    useEffect(() => {
        fetchProjectData();
        fetchADUsers();
    }, [])



    useEffect(() => {
        const { ProjectId } = reportFilter;
        if (ProjectId) {
            fetchProjectUsers();
        }
    }, [reportFilter?.ProjectId]);

    const validateData = () => {
        const errors = {};
        let isValid = true;

        if (!reportFilter.ProjectId) {
            errors.project = 'Enter project name'
            isValid = false;
        }

        if (!reportFilter.LastMonth) {
            if (!reportFilter.ToDate || !reportFilter.ToDate) {
                errors.ToDate = 'Enter From & To Date'
                isValid = false;
            }
        }

        if (!isValid) {
            setError(errors);
        }
        else {
            setError({});
        }
        return isValid;
    }

    const searchReport = () => {
        let isValid = validateData();
        isValid && setSearchButton(!searchButton)
    }

    const projectUserdata = (value) => {
        const adUser = adUsers.find((item) => item.displayName == value)
        setReportFilter({ ...reportFilter, "UserId": adUser ? adUser.id : '' })
    }

    return (
        <div>
            {toggleContentVisibility && <div className='flex gap-2 filter-sections bg-background px-6 py-4 md:px-2 ml-3 md:flex-wrap md:mr-3 '>
                <div className=' md:-w-full'>
                    {error && <p className='text-xs text-red'>{error.project ? error.project : error.ToDate}</p>}
                    <div className='flex gap-2 filter-sections bg-background px-4 py-2 md:px-2 md:-flex-col md:items-baseline md:flex-col'>
                        <div className=' md:w-full'>
                            <div className='flex flex-col'>
                                <label className='text-[12px]'>Projects</label>
                                <select className='border  text-[12px] w-full  h-[35px] rounded-md border-gray text-black  focus:appearance-show '
                                    id="selectBox"
                                    onChange={(e) => setReportFilter({ ...reportFilter, "ProjectId": e.target.value })}
                                >
                                    <option value="">select an option</option>
                                    {project?.map((option, index) => (
                                        <option key={index} value={option.id}>
                                            {option.name}
                                        </option>
                                    ))}

                                </select>
                            </div>
                            <div className="flex flex-col">
                                <label className="text-[12px]">Team/Users</label>
                                <select
                                    className="border  text-[12px] w-full  h-[35px] rounded-md border-gray text-black  focus:appearance-show md:w-[200px] sm:w-auto"
                                    id="selectBox"
                                    // value={reportFilter?.projectUser || ""}
                                    onChange={(e) =>
                                        projectUserdata(e.target.value)
                                    }
                                >
                                    <option value="">Select</option>
                                    {projectUser?.map((option, index) => (
                                        <option key={index} value={option.displayName}>
                                            {option.displayName}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className='flex md:w-full '>
                            <div className='flex flex-col flex-grow '>
                                <label className='text-[12px]'>Date </label>
                                <div className='flex gap-2'>
                                    <input type="Date" name="hours" id="hours" min="0" step="1"
                                        className="timeTextBox flex-grow pl-1 border  text-[12px] h-[35px] rounded-md border-gray text-black"
                                        onChange={(e) => setReportFilter({ ...reportFilter, "FromDate": e.target.value })}></input>
                                    <input type="Date" name="hours" id="hours" min="0" step="1"
                                        className="timeTextBox  flex-grow pl-1 border h-[35px] rounded-md border-gray text-black text-[12px]"
                                        onChange={(e) => setReportFilter({ ...reportFilter, "ToDate": e.target.value })}
                                    ></input>
                                </div>
                            </div>
                        </div>
                        <div className='flex md:w-full '>
                            <label className='text-[12px] pt-[2px]'>Last month </label>
                            <div className='gap-4 pl-1'>
                                <input type="checkbox" id="checkbox" onChange={(e) =>
                                    setReportFilter({ ...reportFilter, "LastMonth": e.target.checked })
                                } />
                            </div>

                        </div>
                    </div>
                </div>
                <Button label={'Search'} className={'hover:bg-blue-700 text-darkgray text-sm h-[35px]  font-medium py-1 px-4 rounded mt-[20px] md:mt-[10px] pt-1 md:w-full bg-yellow text-white'} onClick={searchReport} />
            </div>}

        </div>
    )
}

export default ReportFilter