import React from 'react'
import WorkItem from '../../Atoms/WorkItem'
import TopBar from '../../Molecules/TopBar';
import { Link, useParams } from 'react-router-dom';
const WorkItems = () => {
  const receivedData = useParams();
  const { project_id } = receivedData;
  const breadcrumbData = [
    {
      name: 'Projects',
      href: '/projects',
    },
    {
      name: 'Work item',
     
    },
  ];

return <>
    <div className='flex md:mt-0 md:flex-col'>
      <div className='w-full px-3 py-3'>
        <TopBar breadcrumbData={breadcrumbData}/>
        <WorkItem projectId={project_id} /> 
      </div>
    </div>
  </>
}

export default WorkItems;