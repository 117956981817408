import React from 'react'
import BreadCrumb from '../../Atoms/BreadCrumb'
import { FaSearch } from "react-icons/fa";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { jwtDecode } from "jwt-decode";
import { Button, Heading } from '../../Atoms/index';
import { GrDocumentTime } from "react-icons/gr";
import { RiStackLine } from "react-icons/ri";
import { getLocalStorageData, clearLocalStorage } from '../../../constants/user';
import { FaSignOutAlt } from "react-icons/fa";
import LoginButton from '../../Atoms/LoginButton';
import UserName from '../../Atoms/UserName';
import { Link, useLocation } from "react-router-dom";

const TopBar = ({ breadcrumbData }) => {

  const navigate = useNavigate();
  const [loginData, setLoginData] = useState(null);
  const userId = getLocalStorageData('access_token');

  const location = useLocation();
  const pathName = location.pathname;

  useEffect(() => {
    const token = userId !== null ? jwtDecode(userId) : '';
    setLoginData(token);
  }, [userId]);

  function getInitials(fullName) {
    const words = fullName.split(' ');
    const initials = words.map((word) => word.charAt(0)).join('');
    return initials.toUpperCase();
  }

  const logOut = () => {
    clearLocalStorage();
    navigate('/');
  }

  return (
    <div className='fixed-header'>
      <div className='flex items-center justify-between py-2 shadow'>
        {/* <BreadCrumb breadcrumbData={breadcrumbData} /> */}
        <ul className="flex items-center ">
          <li className=" m-4 flex items-center gap-4 justify-left">
            <RiStackLine className="text-skyblue text-2xl" />
            <Link
              to="/projects"
              className={`text-2xl text-left border-gray  ${(pathName === "/projects" || pathName.includes("/timelog/") || pathName.includes("/workitems")) && "font-bold"
                }`}
            >
              Projects
            </Link>
          </li>
          <li className="m-4 flex items-center gap-4 justify-left">
            <GrDocumentTime className="text-skyblue skyblue text-2xl" />
            <Link
              to="/timelogsummary"
              className={`text-2xl text-left border-gray  ${pathName === "/timelogsummary" && "font-bold"
                }`}
            >
              Time log Summary
            </Link>
          </li>
        </ul>
        <div className='flex  items-center gap-2 pr-14 sm:hidden'>
          {/* <FaSearch className='text-xl' /> */}
          <div className='rounded-full px-3 flex items-center'>
            <span className={`text-sm px-[7px] py-[9px] h-9 w-9 text-white rounded-full text-center ${loginData ? 'bg-green' : 'bg-white'}`}>{loginData && getInitials(loginData?.name)}</span>
            {loginData && <Button label={<FaSignOutAlt className='text-yellow text-2xl' title='Log Out' />} className={'  px-[12px] py-[12px]   hover:bg-blue-700 text-black font-bold ml-2'} onClick={logOut} />}
          </div>

        </div>
      </div>
    </div>

  )
}

export default TopBar