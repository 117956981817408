import React from "react";
import { useEffect } from "react";
import { BiSolidTime } from "react-icons/bi";
import { jwtDecode } from "jwt-decode";
import TimelogTable from "../../Atoms/TimlogTable";
import { useState } from "react";
import axios from "axios";
import { Button, Heading } from "../../Atoms/index";
import moment from "moment";
import { useNavigate } from "react-router";
import {
  getLocalStorageData,
  clearLocalStorage,
} from "../../../constants/user";
import { GrDocumentTime } from "react-icons/gr";
import TopBar from "../../Molecules/TopBar";
import Loader from "../../Atoms/Loader";
import { isMobile } from "../../../constants/user";

const TimelogSummary = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [searchData, setSearchdata] = useState({});
  const [search, setSearch] = useState(false);
  const [project, setProject] = useState([]);
  const [loginData, setLoginData] = useState(null);
  const [loader, setLoader] = useState(true);
  const _isMobile = isMobile();

  const userId = getLocalStorageData("access_token");

  useEffect(() => {
    const token = userId !== null ? jwtDecode(userId) : "";
    setLoginData(token);
  }, [userId]);

  const selectMonth = (fromdate, month) => {
    if (fromdate) {
      setSearchdata({ ...searchData, 'month': 'select' })
      return '';
    }
    else {
      if (month == 'select') {
        return '';
      }
      else if (month?.length > 0) {
        return `&month=${month}`;
      }
      else {

        return `&month=${moment().month() + 1}`;
      }
    }
  }

  const selectYear = (monthText, fromdate) => {
    if (fromdate) {
      return moment(fromdate).year()
    }
    else if(monthText){
      return moment(monthText).year()
    }
    else{
      return moment().year()
    }
  }

  const fetchSearchData = async () => {
    try {
      const { project, month, fromdate, todate,monthText } = searchData;
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL
        }TimeLog/GetAllTimeLogFiltered?UserId=${loginData?.oid}${project?.length > 0 ? `&ProjectId=${project}` : ""
        }${selectMonth(fromdate, month)
        }${fromdate?.length > 0 ? `&fromdate=${fromdate}` : ""}${todate?.length > 0 ? `&todate=${todate}` : ""
        }&year=${selectYear(monthText, fromdate)}`
      );
      response?.data?.map((x) => {
        x.toggle = false;
        return x;
      });
      setData(response.data);
      setLoader(false);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProjectData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}TimeLog/GetAllProjects`
      );
      setProject(response.data.value);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (loginData) {
      setLoader(true);
      fetchSearchData();
    }
  }, [search, loginData]);

  useEffect(() => {
    fetchProjectData();
  }, []);

  const clearFilter = () => {
    setSearchdata({});
    setSearch(!search);
  };

  const breadcrumbData = [
    {
      name: "Time Log Summary",
      href: "/timelogsummary",
    },
  ];

  const currentMonth = moment().month() + 1;
  const previousTwoMonths = [
    moment().subtract(1, "months").month() + 1,
    moment().subtract(2, "months").month() + 1,
  ];

  const monthOptions = [
    { value: 'select', text: "Select Month" },
    { value: currentMonth, text: moment().format("YYYY-MM") },
    {
      value: previousTwoMonths[0],
      text: moment().subtract(1, "months").format("YYYY-MM"),
    },
    {
      value: previousTwoMonths[1],
      text: moment().subtract(2, "months").format("YYYY-MM"),
    },
  ];

  return (
    <div className="flex md:flex-col">
      <div className="w-full">
        <TopBar breadcrumbData={breadcrumbData} />
        <div className="flex items-center gap-2 p-4">
          <GrDocumentTime className="text-yellow text-2xl timeicon " />
          <Heading
            type={"h1"}
            className={`font-medium text-xl`}
            text={"Time Log Summary"}
            dataTestId={"Best-seller"}
          />
        </div>
        <div className="p-4">
          <div className="flex gap-2 items-center filter-sections bg-background px-6 md:px-2 py-4 sm:flex-wrap">
            <div className="filter-bar">
              <div className="flex flex-col">
                <label className="text-[12px]">Team</label>
                <select
                  className="border  text-[12px] w-full  h-[35px] rounded-md border-gray text-black  focus:appearance-show md:w-[200px] sm:w-auto"
                  id="selectBox"
                  value={searchData?.project || ""}
                  onChange={(e) =>
                    setSearchdata({ ...searchData, project: e.target.value })
                  }
                >
                  <option value="">Select</option>
                  {project?.map((option, index) => (
                    <option key={index} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="filter-bar">
              <div className="flex flex-col">
                <label className="text-[12px]">Month</label>
                <select
                  className="border  text-[12px] w-full h-[35px] rounded-md border-gray text-black focus:appearance-show"
                  id="selectBox"
                  value={searchData?.month ? searchData?.month : moment().month() + 1}
                  onChange={(e) =>
                    setSearchdata({ ...searchData, month: e.target.value,monthText:e.target.options[e.target.selectedIndex].text })
                  }
                >
                  {monthOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.text}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex items-center">
              <div className="flex flex-col flex-grow ">
                <label className="text-[12px]">Date </label>
                <div className="flex gap-2">
                  <input
                    placeholder="From"
                    type="Date"
                    name="hours"
                    id="hours"
                    min="0"
                    step="1"
                    className="timeTextBox flex-grow pl-1 border  text-[12px] h-[35px] rounded-md border-gray text-black"
                    value={searchData?.fromdate || ""}
                    onChange={(e) =>
                      setSearchdata({ ...searchData, fromdate: e.target.value })
                    }
                  ></input>
                  <input
                    placeholder="to"
                    type="Date"
                    name="hours"
                    id="hours"
                    min="0"
                    step="1"
                    className="timeTextBox  flex-grow pl-1 border h-[35px] rounded-md border-gray text-black text-[12px]"
                    value={searchData?.todate || ""}
                    onChange={(e) =>
                      setSearchdata({ ...searchData, todate: e.target.value })
                    }
                  ></input>
                </div>
              </div>
            </div>

            <Button
              label={"Clear Filter"}
              className={
                "text-textgray text-sm bg-gray hover:bg-blue-700   py-1 sm:py-2 px-4 md:px-2 rounded sm:ml-[1px]  mt-[14px] "
              }
              onClick={clearFilter}
            />
            <Button
              label={"Search"}
              className={
                "bg-yellow text-sm hover:bg-blue-700 text-white  py-1 px-4 sm:py-2 rounded mt-[14px] "
              }
              onClick={() => setSearch(!search)}
            />
          </div>

          {loader ? <Loader /> : <TimelogTable data={data} />}
        </div>
      </div>
    </div>
  );
};

export default TimelogSummary;
