import React, { useState, useEffect } from 'react'
import { FaScrewdriver, FaRecycle, FaBars, FaFilter } from 'react-icons/fa';
import { BsChevronDown, BsPlusLg, BsArrow90DegRight } from 'react-icons/bs'
import { MdOutlineOpenInFull } from 'react-icons/md'
import { FiFilter } from 'react-icons/fi'
import FilterBar from '../FilterBar'
import TimelogList from '../TimelogList'
import axios from 'axios';
import { TfiLayoutListThumb } from "react-icons/tfi";
import { IoFilterSharp } from "react-icons/io5";
import Loader from "../../Atoms/Loader";
import Pagination from '../Paginations';
import { CSSTransition } from 'react-transition-group';

const WorkItem = ({ projectId }) => {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [filterData, setFilterData] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchButton, setSearchButton] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);

  const headinglist = ['Id', 'Title', 'Assigned to', 'State', 'Area Path', 'Tags', 'Comment', 'Activity Date', 'Time']

  const [isContentVisible, setIsContentVisible] = useState(false);
  const toggleContentVisibility = () => {
    setIsContentVisible(!isContentVisible);
  }
  const buttons = [
    { label: 'New Work Item', icon: <BsPlusLg className='text-blue' /> },
    { label: 'Open in Queries', icon: <BsArrow90DegRight className='text-blue' /> },
    { label: 'Column Options', icon: <FaScrewdriver className='text-blue' /> },
    { label: 'Recycle Bin', icon: <FaRecycle className='text-blue' /> }
  ];


  const fetchData = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}TimeLog/GetAllWorkItemByProjectId`, {
        "projectId": projectId, "page": page, "take": 10, "keyword":searchKeyword, ...filterData
      });
      if (response.status == 200) {
        response?.data?.data?.map(x => {
          x.toggle = false;
          return x;
        })
        setCount(response?.data?.count)
        setData(response?.data?.data);
        setLoader(false);
      }
    }
    catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    setLoader(true);
    fetchData();
  }, [page, searchButton])


  return (
    <div className='pr-12 md:p-2'>
      <div className='m-3 mr-0 md:ml-0 md:px-3 '>
        <div className='flex items-center gap-3 justify-between py-2 ml-2'>
          <div className='flex items-center gap-3'>
            <TfiLayoutListThumb className='text-yellow timeicon text-2xl' />
            <strong className='text-center text-xl'>Work Items</strong>
          </div>
          <div className='border border-gray p-2 text-xl rounded-md'>
            <IoFilterSharp onClick={toggleContentVisibility} />
          </div>
        </div>
      </div>

      <CSSTransition
        in={isContentVisible}
        timeout={300}
        classNames="fade"
        unmountOnExit
      >
        <FilterBar filterData={filterData} searchKeyword={searchKeyword} setFilterData={setFilterData} setSearchKeyword={setSearchKeyword} searchButton={searchButton} setSearchButton={setSearchButton} isContentVisible={isContentVisible} />
      </CSSTransition>

      {
        loader ? <Loader /> : <TimelogList data={data} headinglist={headinglist} searchKeyword={searchKeyword} />
      }

      {count > 10 && !searchKeyword.length && <Pagination totalItems={count} setPage={setPage} />}

    </div>
  )
}

export default WorkItem