import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { RxCross2 } from 'react-icons/rx'
import { AiOutlineEdit } from 'react-icons/ai'
import Modal from '../Modals/index';
import axios from 'axios';
import { useEffect } from 'react';
import moment from 'moment';
import { FaRegEdit } from "react-icons/fa";
import { isMobile } from "../../../constants/user";
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa";
const DynamicTable = ({ headings, data, setEditData,setAddData, setIsEdit, userAccessId, searchParam }) => {
  const [timeLogId, setTimeLogId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [tableData, setTableData] = useState(null);
  const [mobileData, setMobileData] = useState(data);
  const _isMobile = isMobile();
  
  useEffect(() => {
    setTableData(data)
  }, [data])

  const openDeleteModal = (id, userId) => {
    setTimeLogId(id);
    setUserId(userId)
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const editTimeLog = (id) => {
    setEditData(id);
    setAddData(false);
    setIsEdit(true);
  }
  const toggleview = (projectIndex) => {
    const updatedData = [...mobileData];
    updatedData[projectIndex].toggle = !updatedData[projectIndex].toggle;
    setMobileData(updatedData);
  };
  const handleDelete = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}TimeLog/DeleteTimeLog?timeLogId=${timeLogId}&UserId=${userId}`);
      if (response.status === 200) {
        const filteredData = tableData?.filter((item) =>
          item.timeLogId !== timeLogId
        )
        setTableData(filteredData);
        toast.success('Successfully deleted');
      }
    }
    catch (error) {
      console.log(error);
    }
    closeDeleteModal();
  };

  const renderTableHeaders = () => {
    return (
      <>
        {_isMobile ? (
          <div></div>

        ) : (<thead className='p-4 bg-secbg border border-gray'>
          <tr className=''>
            {headings?.map((heading, index) => (
              <th className='text-sm p-4 text-center border border-gray ' key={index}>{heading}</th>
            ))}
          </tr>
        </thead>)}
      </>

    );
  }

  const renderTableRows = () => {
    return (
      <>
        {_isMobile ? (
          <div>
            {tableData?.map((item, projectIndex) => (
              <div>
                <div className="flex items-center justify-between  border border-b border-white">
                  <span className="text-[13px] bg-secbg w-24 px-1 py-2">
                    Name
                  </span>
                  <div className="bg-lightgray w-full rounded-sm px-1 py-[8px] flex justify-between items-center">
                    <span className="text-sm flex items-center justify-between w-full">
                      {item.createdBy}
                      <span onClick={() => toggleview(projectIndex)}>{item?.toggle ? (
                        <FaMinus className="text-sm" />
                      ) : (
                        <FaPlus className="text-sm" />
                      )}</span>
                    </span>
                  </div>
                </div>
                {item?.toggle && (
                  <>
                    <div className="flex items-center justify-between  border border-b border-white">
                      <span className="text-[13px] bg-secbg w-24 px-1 py-2">
                        Time
                      </span>
                      <div className="bg-lightgray w-full rounded-sm px-1 py-[8px] flex justify-between items-center">
                        <span className="text-sm">
                          {`${item?.ticketHours}:${item?.ticketMinutes}`}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center justify-between  border border-b border-white">
                      <span className="text-[13px] bg-secbg w-24 px-1 py-2">
                        Date
                      </span>
                      <div className="bg-lightgray w-full rounded-sm px-1 py-[8px] flex justify-between items-center">
                        <span className="text-sm">
                          {moment(item.timeLogDate).format('YYYY-MM-DD')}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center justify-between  border border-b border-white">
                      <span className="text-[13px] bg-secbg w-24 px-1 py-2">
                        Comment
                      </span>
                      <div className="bg-lightgray w-full rounded-sm px-1 py-[8px] flex justify-between items-center">
                        <span className="text-sm">
                          {item?.comment || '--'}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center justify-between  border border-b border-white">
                      <span className="text-[13px] bg-secbg w-24 px-1 py-2">
                        Action
                      </span>
                      <div className="bg-lightgray w-full rounded-sm px-1 py-[8px] flex justify-between items-center">
                        <span className="text-sm flex items-center gap-2 justify-between">

                          <RxCross2 title='Remove' className='text-red text-xl' onClick={() => openDeleteModal(item?.timeLogId, item?.userId)} />
                          <FaRegEdit title='Edit' onClick={() => editTimeLog(item)} className='text-xl' />
                        </span>
                      </div>
                    </div>

                  </>
                )}

              </div>
            ))}
            <div>

            </div>
          </div>
        ) : (
          <tbody className='p-4'>
            {tableData && tableData?.map((item, index) => (
              <tr key={index} className='border-b border border-gray'>
                <td className='p-4 text-[12px] text-center border border-gray'>{item.createdBy}</td>
                {/* <td className='p-4 text-[12px] flex justify-center  cursor-pointer border-gray'>
            <RxCross2 className='text-red text-sm' onClick={() => openDeleteModal(item.timeLogId)} /></td> */}
                <td className='p-4 text-[12px] text-center border border-gray'>{`${item.ticketHours}:${item.ticketMinutes}`}</td>
                <td className='p-4 text-[12px] text-center  border border-gray'>{moment(item.timeLogDate).format('YYYY-MM-DD')}</td>
                {/* <td className='p-4 text-[12px] text-center  border border-gray'>{item.workItemType}</td> */}
                <td className='p-4 text-[12px] text-center border border-gray'>{item.comment}</td>
                <td className='p-4 text-[12px] cursor-pointer border  gap-2 flex justify-center items-center border-gray border-b-0 border-t-0 border-l-0 border-r-0' >
                  <button disabled={userAccessId !== item?.userId}><RxCross2 title='Remove' className={`${userAccessId !== item?.userId ? 'opacity-50 text-red' : ''} text-red text-xl`} onClick={() => openDeleteModal(item.timeLogId, item.userId)} /></button>
                  <button disabled={userAccessId !== item?.userId}><FaRegEdit title='Edit' onClick={() => editTimeLog(item)} className={`${userAccessId !== item?.userId ? 'opacity-50' : ''} text-xl`} /></button></td>
              </tr>
            ))}
          </tbody>)}


      </>

    );
  }


  return (
    <div className='m-4 mr-12 md:m-0 md:p-4'>
      <table className='w-full  md:overflow-auto md:inline-table' >
        {renderTableHeaders()}
        {renderTableRows()}
      </table>
      <Modal isOpen={isDeleteModalOpen} onClose={closeDeleteModal} onDelete={handleDelete} />
    </div>
  );
}


export default DynamicTable;
