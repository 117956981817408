import ls from 'localstorage-slim';

export const setLocalStorageData = (data, key) => ls.set(key, JSON.stringify(data), { encrypt: true });

export const getLocalStorageData = (key) => JSON.parse(ls.get(key, { decrypt: true }));


export const removeLocalStorageData = (key) => ls.remove(key);


export const clearLocalStorage = () => ls.clear();

export const setUserToken = (token) => ls.set('hash', token, { encrypt: true });

export const getUserToken = () => ls.get('hash', { decrypt: true });

export const isMobile = () => {
  return window.innerWidth <= 880 ? true : false; // Adjust the breakpoint as needed
};

