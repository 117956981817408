import {React,useState,useEffect} from 'react'
import { getLocalStorageData, clearLocalStorage } from '../../../constants/user';
import { jwtDecode } from "jwt-decode";
function UserName() {
    const [loginData, setLoginData] = useState(null);
  const userId = getLocalStorageData('access_token');
  useEffect(() => {
    const token = userId !== null ? jwtDecode(userId) : '';
    setLoginData(token);
  }, [userId]);
  function getInitials(fullName) {
    const words = fullName.split(' ');
    const initials = words.map((word) => word.charAt(0)).join('');
    return initials.toUpperCase();
  }

  return (
    <div className='flex gap-2 items-center py-4 '>
        <span className={`text-sm px-[10px] py-[9px] h-9 w-9 text-white rounded-full text-center 
            ${loginData ? 'bg-green' : 'bg-white'}`}>{loginData && getInitials(loginData?.name)}
        </span>
        <div className='flex flex-col text-justify'>
            <span className='font-semibold text-[12px]'>
                {loginData?.name}
            </span>
            <span className='text-[12px]'>
                {loginData?.unique_name}
            </span>
        </div>
    </div>
  )
}

export default UserName