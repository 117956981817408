import React, { useState, useEffect } from 'react'
import * as XLSX from 'xlsx';
import ReportFilter from '../ReportFilter'
import ReportTimeLog from '../ReportTimelog'
import axios from 'axios';
import { TfiLayoutListThumb } from "react-icons/tfi";
import { IoFilterSharp } from "react-icons/io5";
import { getLocalStorageData, clearLocalStorage } from '../../../constants/user';
import { CSSTransition } from 'react-transition-group';
import Loader from '../Loader';


const ReportItem = () => {
    const [isContentVisible, setIsContentVisible] = useState(false);
    const [reportFilter, setReportFilter] = useState([]);
    const [data, setData] = useState([]);
    const [searchButton, setSearchButton] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);

    const headinglist = ['minutes', 'Project', 'UserName', 'WorkItemId', 'Date', 'Comment']

    const userId = getLocalStorageData('access_token');


    const toggleContentVisibility = () => {
        setIsContentVisible(!isContentVisible);
    }


    const newData = data.map(item => ({
        Minutes: item.time,
        Project: item.project,
        Username: item.userName,
        Workiemid: item.workItemId,
        Date: item.date,
        Comment: item.comment,
    }));

    const exportToExcel = () => {
        const filename = "exported_data";
        const ws = XLSX.utils.json_to_sheet(newData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
        XLSX.writeFile(wb, `${filename}.xlsx`);
    };


    const fetchData = async () => {
        setDataLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}TimeLog/GetProjectReports?${reportFilter?.ToDate ? `ToDate=${reportFilter.ToDate}&` : ''}${reportFilter?.FromDate ? `FromDate=${reportFilter.FromDate}` : ''}&UserId=${reportFilter?.UserId}&ProjectId=${reportFilter?.ProjectId}&LastMonth=${reportFilter?.LastMonth ? reportFilter?.LastMonth : false}`);
            if (response.status == 200) {
                setData(response?.data)
            }
        }
        catch (error) {
            console.log(error);
        }
        setDataLoading(false);
    };

    useEffect(() => {
        if (Object.keys(reportFilter).length !== 0) {
            fetchData();
        }
    }, [searchButton])

    return (
        <div className='pr-12 md:pr-0'>
            <div className='m-3 mr-0 md:mr-3'>
                <div className='flex items-center gap-3 justify-between py-2 ml-2'>
                    <div className='flex items-center gap-3'>
                        <TfiLayoutListThumb className='text-yellow timeicon text-2xl' />
                        <strong className='text-center text-xl'>Report</strong>
                    </div>
                </div>
            </div>

            <CSSTransition
                in={!isContentVisible}
                timeout={300}
                classNames="fade"
                unmountOnExit
            >
                <>
                    <ReportFilter reportFilter={reportFilter} setReportFilter={setReportFilter} searchButton={searchButton} setSearchButton={setSearchButton} isContentVisible={isContentVisible} />
                    {
                        dataLoading ? (
                            <Loader />
                        ) : (

                            data.length > 0 && <ReportTimeLog data={data} exportToExcel={exportToExcel} headinglist={headinglist} />

                        )
                    }
                </>
            </CSSTransition>

        </div>
    )
}

export default ReportItem