import React, { useState, useEffect } from "react";
import TopBar from "../../Atoms/TopBar";
import WorkStatus from "../../Atoms/WorkStatus";
import CustomTab from "../../Molecules/Tab";
import TimeBar from "../../Atoms/TimeBar";
import DynamicTable from "../../Atoms/Tables";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { getLocalStorageData } from "../../../constants/user";
import Loader from "../../Atoms/Loader";
import TopBars from "../../Molecules/TopBar";
import Pagination from "../../Atoms/Paginations";
import { Link, useParams } from "react-router-dom";
const Timelog = () => {
  const receivedData = useParams();
  const { project_id, workitem_id } = receivedData;
  const [data, setData] = useState(null);
  const [status, setStatus] = useState("");
  const [addData, setAddData] = useState(true);
  const [editData, setEditData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [loginData, setLoginData] = useState(null);
  const [loader, setLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const [existData, setExistData] = useState([]);
  const userId = getLocalStorageData("access_token");
  const [estimateData, setEstimate] = useState(null);
  const [serach, setSerach] = useState(false);
  const [searchParam, setsearchParam] = useState("");
  const [searchData, setSearchData] = useState([]);

  useEffect(() => {
    const token = userId !== null ? jwtDecode(userId) : "";
    setLoginData(token);
  }, [userId]);

  const headings = ["Name", "Time", "Date", "Comment", "Action"]; // type is pending

  const fetchData = async () => {
    const body = {
      UserId: loginData?.oid,
      ProjectId: project_id,
      WorkItemId: workitem_id,
      Page: page,
      Take: 10,
      token: userId,
      Keyword: searchParam
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}TimeLog/GetAllTimeLog`,
        body
      );

      setData(response.data);
      setExistData(response?.data);
      setCount(response?.data?.count);
      setEstimate(response?.data.estimate);
      setLoader(false);
    } catch (error) {
      console.log(error);
    };
  };

  useEffect(() => {
    if (loginData) {
      fetchData();
    }
  }, [addData, loginData, page, serach]);

  const breadcrumbData = [
    {
      name: "Projects",
      href: "/projects",
    },

    {
      name: "Work Item",
      href: `/workitems/${project_id}`,
    },
    {
      name: data?.workItemData?.title
    }
  ];


  const handleFilterClick = () => {
    setSerach(!serach)
    setLoader(!loader)
  };

  return (
    <>
      <div className="flex  md:flex-col ">
        <div className="w-full px-2 py-2">
          <TopBars breadcrumbData={breadcrumbData} />
          <TopBar data={data?.workItemData} />
          <WorkStatus
            status={status}
            setStatus={setStatus}
            data={data?.workItemData}
          />
          <TimeBar
            status={status}
            setStatus={setStatus}
            handleFilterClick={handleFilterClick}
            setsearchParam={setsearchParam}
            addData={addData}
            setAddData={setAddData}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            editData={editData}
            setEditData={setEditData}
            data={receivedData}
            loginData={loginData}
            estimate={estimateData}
            serach={serach}
            setSerach={setSerach}
            searchParam={searchParam}
            loader={loader}
            setLoader={setLoader}
          />
          {loader ? (
            <Loader />
          ) : (
            <DynamicTable
              searchParam={searchParam}
              userAccessId={loginData?.oid}
              headings={headings}
              data={data?.timelog}
              setIsEdit={setIsEdit}
              setEditData={setEditData}
              setAddData={setAddData}
            />
          )}
          {count > 10 && !searchParam.length && (
            <div className="pr-12">
              <Pagination totalItems={count} setPage={setPage} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Timelog;
