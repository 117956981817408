import React from 'react'
import './App.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppRouter from './Routers/AppRouters';


function App() {
  return (
    <>
      <AppRouter />
      <ToastContainer />
    </>
  );
}


export default App;
