import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Timelog from '../components/Organisms/TimelogDetails';
import TimelogSummary from '../components/Organisms/TimelogSummary';
import WorkItems from '../components/Organisms/WorkItems';
import Projects from '../components/Organisms/Projects'
import Login from '../components/Organisms/Login';
import Reports from '../components/Organisms/Reports';
import AccessPage from '../components/Organisms/AccessPage';

function RequireAuth({ children }) {
    const authed = localStorage.getItem('access_token');
    if (authed) {
        return children
    } else {
        return <Login />
    }
}

const AppRouter = () => {
    return <BrowserRouter>
        <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/timelogsummary" element={<RequireAuth><TimelogSummary /></RequireAuth >} />
            <Route exact path="/timelog/:project_id/:workitem_id" element={<RequireAuth><Timelog /></RequireAuth >} />
            <Route exact path="/workitems/:project_id" element={<RequireAuth><WorkItems /></RequireAuth >} />
            <Route exact path="/projects" element={<RequireAuth><Projects /></RequireAuth >} />
            <Route exact path="/reports" element={<RequireAuth><Reports /></RequireAuth >} />
        </Routes>
    </BrowserRouter>
}


export default AppRouter;
