import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Input, Select } from "../index";
import { useSelect } from "@material-tailwind/react";
import axios from "axios";
import { Button } from "../index";
import { FaRegClock } from "react-icons/fa";
import { FaSearch } from "react-icons/fa";
import moment from "moment";
import { RxCross2 } from "react-icons/rx";
const TimeBar = ({
  status,
  addData,
  setAddData,
  editData,
  setEditData,
  data,
  loginData,
  estimate,
  isEdit,
  setIsEdit,
  setFilteredData,
  setsearchParam,
  searchParam,
  handleFilterClick,
  setTableData,
  serach,
  setSerach,
  loader,
  setLoader
}) => {
  const { project_id, workitem_id } = data;
  const [error, setError] = useState({});
  const [taskData, setTaskData] = useState({
    userId: loginData?.oid,
    ticketHours: 0,
    ticketMinutes: 0,
    projectId: project_id,
    workItemId: workitem_id,
    timeLogDate: moment().format("YYYY-MM-DD"),
    CreatedBy: loginData?.name,
    ticketState: status,
  });

  const [selectEnum, setSelectEnum] = useState([]);
  const estimates = estimate;
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}TimeLog/GetProjectAndWorkItemEnum`
      );
      setSelectEnum(response.data?.workItemEnum);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setTaskData({
      ...taskData,
      userId: loginData?.oid,
      CreatedBy: loginData?.name,
    });
  }, [loginData]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setTaskData((prevTaskData) => ({
      ...prevTaskData,
      ticketState: status,
    }));

  }, [status]);

  useEffect(() => {
    setTaskData((prevTaskData) => ({
      ...prevTaskData,
      ticketState: status,
      ticketHours: editData?.ticketHours,
      ticketMinutes: editData?.ticketMinutes,
      timeLogDate: editData?.timeLogDate
        ? editData.timeLogDate
        : moment().format("YYYY-MM-DD"),
      workItemType: editData?.workItemType,
      comment: editData?.comment,
    }));

  }, [editData]);

  const validateData = () => {
    const errors = {};
    let isValid = true;

    if (
      (!taskData?.ticketHours || parseInt(taskData?.ticketHours, 10) === 0) &&
      (!taskData?.ticketMinutes || parseInt(taskData?.ticketMinutes, 10) === 0)
    ) {
      errors.timeLog = "Enter time Log";
      isValid = false;
    }

    if (!taskData.workItemType) {
      errors.workItemType = "Enter what the task is for";
      isValid = false;
    }

    if (!taskData.comment) {
      errors.comment = 'Enter the task comment'
      isValid = false;
    }

    if (status == 'ToDo' || status == 'To Do') {
      errors.status = 'Ticket has not been activated yet!'
      isValid = false;
    }

    if (status == 'Closed') {
      errors.status = 'Ticket is already closed'
      isValid = false;
    }

    if (!isValid) {
      setError(errors);
    } else {
      setError({});
    }
    return isValid;
  };

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);


  const serachByName = (e) => {
    setsearchParam(e.target.value);
  };


  const addTimeLog = async () => {

    const isValid = validateData();
    if (!isValid) {
      return;
    }

    if (!isButtonDisabled) {
      setIsButtonDisabled(true);
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 1000);
      setAddData(true);
    }


    if (!isEdit) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}TimeLog/AddTimeLog`,
          taskData
        );
        if (response.status === 200) {
          setTaskData({
            userId: loginData?.oid,
            ticketHours: 0,
            ticketMinutes: 0,
            projectId: project_id,
            workItemId: workitem_id,
            timeLogDate: moment().format("YYYY-MM-DD"),
            CreatedBy: loginData?.name,
            ticketState: status,
          });
          setAddData(false);
          toast.success("Successfully added");
        }
      } catch (error) {
        console.log(error);
      }
      return setEditData(null);
    } else {
      const { userId, projectId, ...newData } = taskData;
      const updatedData = {
        ...newData,
        timeLogId: editData.timeLogId,
        UpdatedBy: loginData?.name,

      };
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}TimeLog/UpdateTimeLog`,
          updatedData
        );
        if (response.status === 200) {
          setEditData({
            userId: loginData?.oid,
            ticketHours: 0,
            ticketMinutes: 0,
            projectId: projectId,
            workItemId: workitem_id,
            timeLogDate: moment().format("YYYY-MM-DD"),
            ticketState: status,
          });
          setAddData(false);
          setIsEdit(false);
          toast.success("Successfully updated");
        }
      } catch (error) {
        console.log(error);
      }
    }

  };

  const widthPercentage = estimates?.percentage;
  let spent = ((estimates && estimates?.spentMinute) / 60).toString();
  let remaining = ((estimates && estimates?.remainingMinute) / 60).toString();
  let remainingHour = remaining === "0" ? 0 : Number(remaining.split(".")[0]);
  let remainingMinute =
    remaining === "0" ? 0 : (remaining - remainingHour) * 60;
  let spentHour = spent === "0" ? 0 : Number(spent.split(".")[0]);
  let spentMinute = spent === "0" ? 0 : (spent - spentHour) * 60;
  const background = widthPercentage < 100 ? "#d67f3c" : "#E80000";

  const clearSearch = (e) => {
    setSerach(!serach);
    setsearchParam("");
    setLoader(!loader);
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleFilterClick();
    }
  };

  return (
    <div className="p-4 pr-12 md:p-4">
      <div className="flex gap-1 items-center">
        <div>
          <div className="flex gap-1 items-center">
            <FaRegClock className="text-yellow text-2xl " />
            <label className="text-xl sm:text-sm sm:leading-tight font-semibold">
              Estimated Time: {estimates?.estimatedMinute / 60} hours Completed{" "}
              {parseFloat(spentHour).toFixed(2)} hours :{" "}
              {parseFloat(spentMinute).toFixed(2)} minutes Remaining{" "}
              {parseFloat(remainingHour).toFixed(2)} hours :{" "}
              {parseFloat(remainingMinute).toFixed(2)} minutes
            </label>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <div className="w-full bg-gray-200 mb-4 dark:bg-gray-700 max-w-[650px] bg-gray  h-6 rounded-sm">
          <div
            className="bg-blue-600  w-full max-w-[650px] h-6 rounded-sm dark:bg-blue-500 "
            style={{ width: `${widthPercentage}%`, background: background }}
          >
            <span className="pl-2 text-white ">{widthPercentage}%</span>
          </div>
        </div>
      </div>
      {error && (
        <p className="text-xs text-red">
          {error && <p className='text-xs text-red'>{error.timeLog || error.workItemType || error.comment || error.status}</p>}
        </p>
      )}
      <div>
        <div className=" mt-3">
          <div className="flex gap-2 items-center  md:flex-wrap">
            <div className="flex gap-1  flex-col items-start filter-bar">
              <label className="text-[14px] font-medium">Hours</label>
              <Input
                type="number"
                name="hours"
                id="hours"
                min="0"
                step="1"
                className="timeTextBox  pl-1 border border-gray text-[12px] w-full rounded-md h-10"
                value={taskData && taskData.ticketHours}
                onChange={(e) =>
                  setTaskData({ ...taskData, ticketHours: e.target.value })
                }
              />
            </div>
            <div className="flex gap-1  flex-col items-start filter-bar">
              <label className="text-[14px] font-medium">Min</label>
              <Input
                type="number"
                name="minutes"
                id="minutes"
                min="0"
                step="15"
                className="timeTextBox w-full pl-1 border border-gray text-[12px] rounded-md h-10"
                value={taskData && taskData.ticketMinutes}
                onChange={(e) =>
                  setTaskData({ ...taskData, ticketMinutes: e.target.value })
                }
              />
            </div>

            <div className="flex gap-1 items-start flex-col filter-bar">
              <label className="text-[14px] font-medium">Date</label>
              <Input
                type="Date"
                name="date"
                id="date"
                className="timeTextBox w-18  pl-1 border border-gray text-[12px] w-full rounded-md h-10"
                value={
                  (taskData && taskData?.timeLogDate?.substring(0, 10)) ||
                  moment().format("YYYY-MM-DD")
                }
                onChange={(e) =>
                  setTaskData({ ...taskData, timeLogDate: e.target.value })
                }
              />
            </div>

            <div className="flex gap-1 items-start flex-col filter-bar">
              <label className="text-[14px] font-medium">Options</label>
              <Select
                id="selectBox"
                className="text-[14px] font-light border md:w-full border-gray rounded-md h-10"
                value={(taskData && taskData.workItemType) || ""}
                selectOptions={selectEnum}
                onChange={(e) =>
                  setTaskData({ ...taskData, workItemType: e.target.value })
                }
              />
            </div>
            <div className="flex gap-1 items-start flex-col flex-grow filter-bar">
              <label className="text-[14px] font-medium">Comment</label>
              <input
                type="text"
                name="comment"
                className="timeTextBox  pl-1 border border-gray text-[12px]  w-full rounded-md h-10"
                value={(taskData && taskData.comment) || ""}
                onChange={(e) =>
                  setTaskData({ ...taskData, comment: e.target.value })
                }
                required
              />
            </div>
            <div>
              <div
                className="flex items-start border border-gray px-4 gap-1 bg-yellow rounded-md mt-6"
                onClick={() => addTimeLog()}
              >
                <label className="text-[14px] font-medium"></label>
                <Button
                  label={isEdit ? 'Update' : 'Add'}
                  className="text-[12px] px-4 py-[10px] text-white  font-semibold"
                  disabled={isButtonDisabled}
                />
              </div>
            </div>
            <div>
              <div class="mt-6">
                <div class="relative flex w-full  items-stretch flex-nowrap">
                  <div className="flex items-center relative m-0 -mr-0.5  min-w-0 h-10 flex-auto rounded-l border border-gray rounded-md border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200">
                    <input
                      type="input"
                      class="outline-none "
                      placeholder="Search by Name"
                      value={searchParam}
                      aria-label="Search"
                      onChange={(event) => serachByName(event)}
                      aria-describedby="button-addon1"
                      onKeyDown={handleKeyDown}
                    />
                    {searchParam && <RxCross2 onClick={(e) => clearSearch(e)} />}

                  </div>
                  <button
                    class="relative z-[2] h-10 flex items-center rounded-r bg-yellow px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-primary-700 hover:shadow-lg focus:bg-primary-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary-800 active:shadow-lg"
                    type="button"
                    id="button-addon1"
                    data-te-ripple-init
                    onClick={() => handleFilterClick()}
                    data-te-ripple-color="light"
                  >
                    <FaSearch className="text-white text-sm" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeBar;
